export namespace ArrayUtils {
    export function joinIfDefined(array: any[], join = " "): string {
        return array.filter(val => val).join(join);
    }
}

declare global {
    interface Array<T> {
        distinct<T>(propertyAccessor?: (item: T) => any);

        skipNullValues(): T[];

        replace<T>(item: T, ...newItems: T[]): Array<T>;
    }
}

Array.prototype.replace = function <T>(item: T, ...newItems: T[]) {
    return [...this.slice(0, this.indexOf(item)), ...newItems, ...this.slice(this.indexOf(item) + 1, this.length)];
};

Array.prototype.distinct = function <T>(propertyAccessor: (item: T) => any = item => item) {
    return this.filter((value, index) => {
        const firstObject = this.find(item => propertyAccessor(item) === propertyAccessor(value));
        return this.indexOf(firstObject) === index;
    });
};


Array.prototype.skipNullValues = function() {
    return this.filter(x => !!x);
};
